module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["xxx"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","de"],"defaultLanguage":"en","fallbackLanguage":"en","generateDefaultLanguagePage":false,"localeJsonSourceName":"i18nLocale","localeJsonNodeName":"i18nLocales","siteUrl":"https://nighttec.net","i18nextOptions":{"defaultNS":"common","lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/shop/:handle","getLanguageFromPath":true},{"matchPath":"/:lang?/shop/category/:handle","getLanguageFromPath":true},{"matchPath":"/impressum","languages":["de"]},{"matchPath":"/settings","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef4be863c809ed38cb8c1ea66419db5a"},
    },{
      plugin: require('../node_modules/gatsby-source-shopify-translations/gatsby-browser.js'),
      options: {"plugins":[],"shopName":"nighttec.myshopify.com","shopifyPassword":"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","accessToken":"6b008010a5bd1823039b6ebb74ba0c4c","defaultLang":"en","prefixDefault":true,"locales":["en","de"],"waitingGatsbySourceShopify":5000,"sourceOnlyMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-97218477-1","anonymize":true,"respectDNT":true,"head":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"644972102797315"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
